const title = "北斗电子浮标管理平台";
const logo = require("../assets/images/public/logo.png");
// const baseUrl = 'http://10.100.20.35:13016';  // sl
// const baseUrl = "http://nxa34kegc963.allynav.cn:9000/receiver"; // 测试
const baseUrl = "https://bdrmp.allynav.cn/api"; // 正式新华为云


// 默认皮肤
const defaultSkin = [
  {
    label: "默认",
    topColor: "#ffffff",
    topBg: ["#539D56", "#357437"],
    sideColor: "#265628",
    sideBg: ["#ffffff", "#ffffff"],
    isDefault: true,
    checked: true,
  },
];

// 管理端侧边栏
const meunNav = [
  {
    route: "positionMap",
    meta: {
      title: "位置地图",
      icon: require("../assets/images/public/positionMap.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      oneChildrenHidden: true,
    },
    children: [
      {
        route: "positionMap",
        meta: {
          title: "位置地图",
          icon: require("../assets/images/public/positionMap.png"),
          sort: 10,
        },
      }
    ],
  },
  {
    route: "taskManage",
    meta: {
      title: "任务管理",
      icon: require("../assets/images/public/taskManage.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      oneChildrenHidden: true,
    },
    children: [
      {
        route: "taskManage",
        meta: {
          title: "任务管理",
          icon: require("../assets/images/public/taskManage.png"),
          sort: 10,
        },
      }
    ],
  },
  {
    route: "system",
    meta: {
      title: "坐标系统",
      icon: require("../assets/images/public/system.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      oneChildrenHidden: true,
    },
    children: [
      {
        route: "system",
        meta: {
          title: "坐标系统",
          icon: require("../assets/images/public/system.png"),
          sort: 10,
        },
      }
    ],
  },
  {
    route: "sectionPoint",
    meta: {
      title: "截面点对",
      icon: require("../assets/images/public/sectionPoint.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      oneChildrenHidden: true,
    },
    children: [
      {
        route: "sectionPoint",
        meta: {
          title: "截面点对",
          icon: require("../assets/images/public/sectionPoint.png"),
          sort: 10,
        },
      },
    ],
  },
  {
    route: "dataManage",
    meta: {
      title: "数据管理",
      icon: require("../assets/images/public/dataManage.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      // oneChildrenHidden: false,
    },
    children: [
      {
        route: "dataManage",
        meta: {
          title: "数据管理",
          icon: require("../assets/images/public/dataManage.png"),
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      },
    ],
  },
  {
    route: "deviceManage",
    meta: {
      title: "设备管理",
      icon: require("../assets/images/public/equipManage.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      oneChildrenHidden: true,
    },
    children: [
      {
        route: "deviceManage",
        meta: {
          title: "设备管理",
          icon: require("../assets/images/public/equipManage.png"),
          sort: 10,
          hidden: false,
          hiddenSide: false,
          isDetail: false,
        },
      },
    ]
  },
  {
    route: "receiver",
    meta: {
      title: "接收机管理",
      type: "config",
      icon: require("../assets/images/public/manage.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
    },
    children: [
      {
        route: "receiverInfo",
        meta: {
          title: "接收机信息",
          icon: "",
          sort: 2,
          hidden: false,
          isDetail: false,
        },
      },
      {
        route: "updata",
        meta: {
          title: "固件更新",
          icon: "",
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      },
    ],
  },
  {
    route: "status",
    meta: {
      title: "接收机状态",
      icon: require("../assets/images/public/status.png"),
      sort: 1,
      type: "config",
      hidden: false,
      isDetail: false,
      oneChildrenHidden: false,
    },
    children: [
      {
        route: "info",
        meta: {
          title: "位置信息",
          icon: "",
          sort: 10,
          hidden: false,
          hiddenSide: false,
          isDetail: false,
        },
      },
      {
        route: "activity",
        meta: {
          title: "活动",
          icon: "",
          sort: 10,
          hidden: false,
          hiddenSide: false,
          isDetail: false,
        },
      },
      // {
      //   route: "satellites",
      //   meta: {
      //     title: "卫星信息",
      //     // icon: require("../assets/images/public/equipManage.png"),
      //     sort: 10,
      //     hidden: false,
      //     hiddenSide: false,
      //     isDetail: false,
      //   },
      // },
    ]
  },
  {
    route: "config",
    meta: {
      title: "接收机配置",
      type: "config",
      icon: require("../assets/images/public/config.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      oneChildrenHidden: false
    },
    children: [
      {
        route: "nmea",
        meta: {
          title: "NMEA消息",
          // icon: require("../assets/images/public/config.png"),
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      }
      // {
      //   route: "NMEAConfig",
      //   meta: {
      //     title: "NMEA设置",
      //     icon: "111111",
      //     sort: 1,
      //     hidden: false,
      //     isDetail: false,
      //   },
      // },
      // {
      //   route: "configSet",
      //   meta: {
      //     title: "配置集",
      //     icon: "111111",
      //     sort: 1,
      //     hidden: false,
      //     isDetail: false,
      //   },
      // },
    ],
  },

  // {
  //   route: "IOConfig",
  //   meta: {
  //     title: "截面点对",
  //     icon: require("../assets/images/public/jiemian.png"),
  //     sort: 1,
  //     hidden: false,
  //     isDetail: false,
  //     // oneChildrenHidden: false,
  //   },
  //   children: [
  //     {
  //       route: "postSet",
  //       meta: {
  //         title: "截面点对",
  //         icon: require("../assets/images/public/jiemian.png"),
  //         sort: 1,
  //         hidden: false,
  //         isDetail: false,
  //       },
  //     },
  //   ],
  // },
  {
    route: "user",
    meta: {
      title: "用户管理",
      icon: require("../assets/images/public/userManage.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      // oneChildrenHidden: false,
    },
    children: [
      {
        route: "userList",
        meta: {
          title: "用户管理",
          icon: require("../assets/images/public/userManage.png"),
          sort: 1,
          hidden: false,
          hiddenSide: false,
          isDetail: false,
        },
      },
    ],
  },
];

// 导航栏模式
const navMode = "default"; // default 只有侧边栏  sideTop 侧边和顶部都有
// 布局模式
const layoutMode = "navFull"; // navFull  顶部导航栏通栏   sideFull 侧边导航栏通栏
// 是否开启页面缓存
const useRouteCache = true;

// 表格自定义列
const tableItemName = "temp_tableItem";
// 本地皮肤缓存名称
const skinName = "temp_skin";
// 本地token缓存名称
const tokenName = "temp_token";
// 本地重载缓存
const reLoadName = "temp_reload";
// 本地记录当前选择的流速球
const receiverName = "currentReceiver";
// 本地翻译标识
const langFlag = "localLangFlag";
// 本地翻译可选项
const langEnum = [
  {
    label: "中文",
    type: "zh",
    isCheck: false,
    icon: require("../assets/images/public/zh.png"),
    alias: "中文",
  },
  {
    label: "English",
    type: "en",
    isCheck: false,
    icon: require("../assets/images/public/en.png"),
    alias: "英文",
  },
  {
    label: "русский язык",
    type: "ru-RU",
    isCheck: false,
    icon: require("../assets/images/public/ru.png"),
    alias: "俄语",
  },
  {
    label: "Türkçe",
    type: "tr-TR",
    isCheck: false,
    icon: require("../assets/images/public/tr.png"),
    alias: "土耳其语",
  },
  {
    label: "Español",
    type: "es",
    isCheck: false,
    icon: require("../assets/images/public/es.png"),
    alias: "西班牙语",
  },
];
// 天地图key
const mapKey = ["fdf1d64dbb085419e82a8e1a4772bc98"] // 线上
// const mapKey = ["24212dad3dfe30179e23c556eac2723d"] // 测试
// const mapKey = ["f76984e1ae2e88e1945dd2dfafec7374",
//   "15ed9927ab40d747ac3254a255b3b9b4",
//   "af746b368962fc2803eb43a9aa9b42a1",]
// 天地图通道
const mapChannal = [0, 1, 2, 3, 4, 5, 6];
export {
  title,
  baseUrl,
  meunNav,
  tokenName,
  skinName,
  defaultSkin,
  reLoadName,
  navMode,
  tableItemName,
  useRouteCache,
  layoutMode,
  logo,
  langFlag,
  langEnum,
  receiverName,
  mapKey,
  mapChannal
};
